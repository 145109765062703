import React from 'react'
import classes from './Spinner.module.css'

import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Logo from '../../../images/logoHQ/logo.svg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  fabProgress: {
    color: '#604f93',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  }
}))

export default () => {
  const customClasses = useStyles()

  return (
    <div className={classes.Spinner}>
      <div className={customClasses.wrapper}>
        <Fab
          aria-label="save"
          color="default"
          style={{ backgroundColor: 'white' }}
        >
          <img src={Logo} className={classes.Img} alt="spinner" />
        </Fab>
        <CircularProgress size={68} className={customClasses.fabProgress} />
      </div>
    </div>
  )
}
